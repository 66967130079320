<template>
  <b-row class="justify-content-center m-5">
    <b-col
      lg="4"
      md="6"
      class="mb-3"
      v-for="(item, index) in services"
      :key="index"
    >
      <router-link :to="item.route">
        <b-card class="shadow border py-5 text-center">
          <b-icon :icon="item.icon" variant="info" scale="3"></b-icon>
          <h3 class="mt-5">{{ item.title }}</h3>
          <p class="regular text-muted">{{ item.description }}</p>
        </b-card>
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "الخدمات الرئيسية",
          description: "وتشمل جميع الخدمات المصرفية",
          icon: "grid1x2",
          route: "service",
        },
        {
          title: "المصارف",
          description: "تعرف على المصارف العراقية وخدماتها",
          icon: "building",
          route: "banks",
        },
        {
          title: "الأخبار",
          description: "تعرف على أخبار المصارف العراقية",
          icon: "newspaper",
          route: "news",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.col-md-6,
.col-lg-4 {
  transition: 0.3s ease !important;
}
.col-md-6:hover,
.col-lg-4:hover {
  transform: scale(1.1);
}
</style>

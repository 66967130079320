<template>
  <b-container fluid class="p-0">
    <section
      class="d-flex align-items-center p-5 gradient-banner position-relative"
    >
      <div class="shapes-container">
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="1500"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-up-right"
          data-aos-duration="1000"
          data-aos-delay="200"
        ></div>
        <div
          class="shape"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-delay="300"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="200"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="zoom-out"
          data-aos-duration="2000"
          data-aos-delay="500"
        ></div>
        <div
          class="shape"
          data-aos="fade-up-right"
          data-aos-duration="500"
          data-aos-delay="200"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="500"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="0"
        ></div>
        <div
          class="shape"
          data-aos="fade-down"
          data-aos-duration="500"
          data-aos-delay="0"
        ></div>
        <div
          class="shape"
          data-aos="fade-up-right"
          data-aos-duration="500"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="500"
          data-aos-delay="0"
        ></div>
      </div>
      <div class="container animated animatedFadeInUp fadeInUp">
        <div class="row align-items-center">
          <div
            class="
              col-md-6
              order-2 order-md-1
              text-center text-md-right
              animated-right
            "
          >
            <h2 class="text-white font-weight-bold my-3">
              مصرفنا - دليل المصارف العراقية
            </h2>
            <p class="text-white">
              دليل مصرفي عراقي يزود المستخدم بكافة الخدمات المصرفية لزيادة الوعي
              بالخدمات والثقافة المصرفية بالتالي يستطيع المستخدم معرفة الخدمات
              التي تشمله وترفع المستوى الاقتصادي للأفراد والشركات سواء في مجال
              الصحة او التعليم وغيره من الخدمات
            </p>
            <b-button variant="light text-info" class="px-5 py-2 my-3">
              <a href="#mobile">تحميل الأن</a></b-button
            >
          </div>
          <div class="col-md-6 text-center order-1 order-md-2 animated-left">
            <img width="50%" src="@/assets/mobile-app1.png" alt="screenshot" />
          </div>
        </div>
      </div>
    </section>
    <Ads class="animated-boottom"></Ads>
    <About class="animated-boottom"></About>
    <Services class="animated-boottom"></Services>
    <VLatestBlogs
      class="animated-boottom"
      :list="articles"
      title="المقالات"
      :categories="1"
    ></VLatestBlogs>
    <Mobile id="mobile"></Mobile>
    <VLatestBlogs
      :list="news"
      title="الأخبار"
      :categories="2"
      class="animated-boottom"
    ></VLatestBlogs>
  </b-container>
</template>

<script>
import About from "./components/about";
import Services from "./components/services";
import Ads from "./components/ads";
import Mobile from "./components/mobile";
import ScrollReveal from "scrollreveal";

export default {
  components: { Services, About, Ads, Mobile },
  computed: {
    articles() {
      return this.$store.state.website.articles.list;
    },
    news() {
      return this.$store.state.website.news.list;
    },
  },
  mounted() {
    this.$store.dispatch("website/about");
    this.$store.dispatch("website/articles/get");
    this.$store.dispatch("website/news/get");
    ///
    ScrollReveal().reveal(".animated-right", {
      duration: 3000,
      origin: "right",
      distance: "300px",
    });
    ScrollReveal().reveal(".animated-left", {
      duration: 3000,
      origin: "left",
      distance: "300px",
    });
    ScrollReveal().reveal(".animated-boottom", {
      duration: 3000,
      origin: "bottom",
      distance: "300px",
    });
  },
};
</script>

<style lang="scss"></style>

<template>
  <b-card
    class="border-0 shadow overflow-hidden mb-5"
    style="margin: -5rem 5rem"
  >
    <b-carousel
      :interval="3000"
      controls
      indicators
      no-animation
      v-if="advertisements != null && advertisements.length > 0"
    >
      <b-carousel-slide
        v-for="item in advertisements"
        :key="item.id"
        :img-src="item.image"
      >
      </b-carousel-slide>
    </b-carousel>
    <b-card
      v-else
      id="noAds"
      class="
        d-flex
        flex-column
        justify-content-center
        align-items-center
        bg-info
        w-100
        h-100
        text-light
      "
    >
      <img
        src="@/assets/logo_info.svg"
        width="120"
        alt="loader_logo"
        class="mb-3"
      />
      <h3>ضع أعلانك في أكبر دليل للمصارف العراقية والخدمات والأخبار المالية</h3>
    </b-card>
  </b-card>
</template>

<script>
export default {
  computed: {
    advertisements() {
      return this.$store.state.website.advertisements;
    },
  },
  mounted() {
    this.$store.dispatch("website/advertisements");
  },
};
</script>

<style lang="scss">
.carousel-item {
  .img-fluid {
    height: 480px !important;
  }
}
#noAds {
  height: 480px !important;
  .card-body {
    text-align: center;
    display: contents;
    align-items: center;
  }
}
</style>

<template>
  <div class="m-5 p-5">
    <b-row align-v="center">
      <b-col lg="6" md="6" class="order-2 order-md-1">
        <div class="d-flex flex-column animated-right">
          <h1 class="text-info">مصرفنا</h1>
          <p class="my-3" style="line-height: 2">
            دليلك الشامل والمتكامل لكل المصارف العراقية والخدمات الرئيسية
            المقدمة من المصارف للأفراد والشركات واحدث الاخبار المالية
          </p>
          <ul class="d-flex flex-row p-0" style="list-style: none">
            <li class="ml-3">
              <a
                href="https://apps.apple.com/iq/app/masrafna-%D9%85%D8%B5%D8%B1%D9%81%D9%86%D8%A7/id1664131687"
                ><img
                  src="@/assets/apple-store.jpg"
                  width="120"
                  alt="Apple Store"
              /></a>
            </li>
            <li class="mx-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.masrafna"
                ><img
                  src="@/assets/google-play.jpg"
                  width="120"
                  alt="Google Play"
              /></a>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col lg="6" md="6" class="text-center order-1 order-md-2 animated-left">
        <img src="@/assets/mobile-app2.png" class="w-75" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ScrollReveal from "scrollreveal";
export default {
  mounted() {
    ScrollReveal().reveal(".animated-right", {
      duration: 3000,
      origin: "right",
      distance: "300px",
    });
    ScrollReveal().reveal(".animated-left", {
      duration: 3000,
      origin: "left",
      distance: "300px",
    });
  },
};
</script>

<style></style>

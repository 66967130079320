<template>
  <div class="text-center m-5">
    <b-row class="my-5 d-flex justify-content-center">
      <b-col sm="8" align-v="center">
        <h1 class="text-info">مصرفنا</h1>
        <div class="mb-3 p-1" id="content" v-html="about"></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  computed: {
    about() {
      return this.$store.state.website.about;
    },
  },
};
</script>

<style>
#content {
  word-spacing: 2px;
  line-height: 3;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
